import React from "react"
import DynamicComponent from "./DynamicComponent"
import SbEditable from 'storyblok-react'

const Page = ({ blok, t }) => {
  const content =
    blok.body &&
    blok.body.map(childBlok => <DynamicComponent blok={childBlok} key={childBlok._uid} t={t}/>)
  const hasTitle = blok.title && blok.title.length ? (
      <h1 className="text-5xl font-bold font-serif text-primary tracking-wide text-center py-8">{ blok.title }</h1>) : null
  return (
    <div className="blog-page">
      <SbEditable content={blok} key={blok._uid} t={t}>
          {/* { hasTitle } */}
          { content }
      </SbEditable>
    </div>
  )
}

export default Page
